var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.source.type == 'shift-title')?_vm._m(0):_vm._e(),(_vm.source.type == 'client-title')?_vm._m(1):_vm._e(),(_vm.source.type == 'no-tasks')?_vm._m(2):_vm._e(),(_vm.source.type == 'all-tasks-completed')?_vm._m(3):_vm._e(),(_vm.source.type == 'task')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.openDialog()}}},[_c('v-list-item-content',{class:_vm.taskData.status != 'open' ? 'checkout' : ''},[_c('v-list-item-subtitle',[(_vm.taskData.status == 'assisted')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("people")])],1):_vm._e(),(_vm.taskData.status == 'supervised')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("visibility")])],1):_vm._e(),(_vm.taskData.status == 'independent')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("person")])],1):_vm._e(),(_vm.taskData.status == 'canceled')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("cancel")])],1):_vm._e(),(_vm.taskData.status == 'postponed')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("fast_forward")])],1):_vm._e(),(_vm.taskData.status == 'doneIdr')?_c('span',[_c('v-icon',{attrs:{"small":""}},[_vm._v("check_circle_outline")])],1):_vm._e(),_c('span',{class:'task-group-' + _vm.taskData.lpTaskNumber.toString().slice(0, 1)},[_vm._v(_vm._s(_vm.taskData.lpTaskNumber))]),_vm._v(" "+_vm._s(_vm.taskData.lpTaskName)+" "+_vm._s(_vm.isRepositioningTask ? (" (" + (_vm.taskData.order) + ")") : "")+" "),(typeof _vm.taskData.postponed != 'undefined')?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" ( "),_c('v-icon',{attrs:{"color":"accent","small":""}},[_vm._v("fast_forward")]),_vm._v(") ")],1)]}}],null,false,1068336555)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.moved-task")))])]):_vm._e(),(_vm.taskData.lpTaskSocial)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent","small":""}},on),[_vm._v("cake")])]}}],null,false,2891708366)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.social-task")))])]):_vm._e(),(_vm.taskData.lpTaskCognitive)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent","small":""}},on),[_vm._v("extension")])]}}],null,false,6056603)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.cognitive-task")))])]):_vm._e(),(_vm.taskData.lpTaskEveryday)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent","small":""}},on),[_vm._v("repeat")])]}}],null,false,2921536885)},[_c('span',[_vm._v(_vm._s(_vm.$t("tasks.daily-task")))])]):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.source.data))])],1)},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"client-title",attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.source.data))])],1)},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("tasks.no-tasks-selected")))])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v(_vm._s(_vm.$t("tasks.all-tasks-completed")))])])}]

export { render, staticRenderFns }