<template>
  <div v-if="isButtonShown">
    <custom-button
      v-if="!(isScheduleAccepted && sortedShiftKeys.length == 0)"
      @click="isDialogOpen = true"
      :text="
        isScheduleAccepted
          ? 'Rītdienas grafiks ir apstiprināts'
          : 'Apstiprināt rītdienas grafiku'
      "
      :icon="isScheduleAccepted ? 'check_circle_outline' : 'touch_app'"
      :color="isScheduleAccepted ? 'secondary' : 'red'"
    />
    <v-dialog v-model="isDialogOpen" persistent max-width="600px">
      <v-card class="px-2 center">
        <v-row align="center" justify="center">
          <h1 class="py-5">Rītdienas grafiks</h1>
        </v-row>
        <div>
          <ul v-if="sortedShiftKeys.length > 0">
            <li
              v-for="shiftKey in sortedShiftKeys"
              :key="shiftKey"
              class="mb-1"
            >
              <h4>{{ getShiftText(shiftKey) }}</h4>
            </li>
          </ul>
          <div v-else align="center">
            <h2>Rīt nav paredzēti uzdevumi</h2>
          </div>
        </div>
        <v-card-actions>
          <v-spacer />
          <custom-button
            v-if="!isScheduleAccepted"
            type="textBtn"
            text="Apstiprināt"
            icon="check_circle_outline"
            @click="acceptWorkSchedule"
          />
          <custom-button
            type="warningtext"
            :text="$t('btn.close')"
            icon="close"
            @click="isDialogOpen = false"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else class="py-0 my-0"></div>
</template>

<script>
import { db } from "@/main";
import CustomButton from "./CustomButton.vue";
import { isEqual, isNil } from "lodash";
import { timeInRiga } from "@/helpers/dayjsDates";
export default {
  components: { CustomButton },
  props: ["employeeData"],
  data() {
    return {
      isDialogOpen: false,
      isButtonShown: false,
      isScheduleAccepted: false,
      clientsList: {},
      sheduledVisitsTimes: {},
      sortedShiftKeys: [],
      tomorrowsScheduleAgreements: {},
      monthKey: null,
      isoDate: null,
      todayIso: new Date().toISOString().substring(0, 10),
    };
  },
  watch: {
    employeeData: function (val) {
      this.loadData();
    },
  },
  created() {
    let today = new Date();
    today.setHours(12, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.monthKey = tomorrow.toISOString().substring(0, 7);
    this.isoDate = tomorrow.toISOString().substring(0, 10);
    this.loadData();
  },
  methods: {
    getShiftText(shiftKey) {
      let [clientId, year, month, date, shiftNr] = shiftKey.split("-");

      // let timeInMinutes = this.sheduledVisitsTimes[shiftKey];
      // let hours = Math.floor(timeInMinutes / 60);
      // let minutes = timeInMinutes % 60;
      // let timeFormatted = `${hours > 9 ? hours : `0${hours}`}:${
      //   minutes > 9 ? minutes : `0${minutes}`
      // }`;
      // let clientName = this.clientsList[clientId]
      //   ? this.clientsList[clientId].clientName
      //   : null;
      let client = this.clientsList[clientId];
      if (client) {
        let timeText = `${this.$t("visits.visit-nr")}${parseInt(shiftNr) + 1}`;
        if (client.visitsScheduleTimes) {
          let foundTime = client.visitsScheduleTimes.find((time) =>
            isEqual(time.index, parseInt(shiftNr))
          );
          if (foundTime) {
            timeText = foundTime.timeFormatted;
          }
        }
        return `${timeText} - ${client.clientName} ${
          client.clientAddress ? `(${client.clientAddress})` : ""
        }`;
      }
    },
    async acceptWorkSchedule() {
      this.isScheduleAccepted = true;
      let employeeId = this.employeeData.userId;
      this.tomorrowsScheduleAgreements[employeeId] = true;
      this.isDialogOpen = false;
      await db
        .collection("tomorrowsScheduleAgreementsList")
        .doc(this.todayIso)
        .set(this.tomorrowsScheduleAgreements);
    },
    async loadData() {
      //START Get tomorrowsScheduleAgreements
      let tomorrowsScheduleAgreementsResponse = await db
        .collection("tomorrowsScheduleAgreementsList")
        .doc(this.todayIso)
        .get();
      let employeeId = this.employeeData.userId;
      if (tomorrowsScheduleAgreementsResponse.exists) {
        let tomorrowsScheduleAgreements =
          tomorrowsScheduleAgreementsResponse.data();
        let isEmployeeScheduledToWork = Object.keys(
          tomorrowsScheduleAgreements
        ).includes(employeeId);
        this.tomorrowsScheduleAgreements = Object.assign(
          {},
          tomorrowsScheduleAgreements
        );
        //END Get tomorrowsScheduleAgreements
        if (isEmployeeScheduledToWork) {
          this.isScheduleAccepted = tomorrowsScheduleAgreements[employeeId];
          this.isButtonShown = true;
          let employeeServices = this.employeeData.aiderServices;
          let aiderService =
            employeeServices && employeeServices.length > 0
              ? employeeServices[0]
              : null;
          if (aiderService) {
            let documentKeyFirstPart = `${aiderService}-${this.monthKey}`;
            //START Get shiftPlanning
            let shiftsPlanningResponse = await db
              .collection("shiftPlanning")
              .doc(`${documentKeyFirstPart}`)
              .get();
            if (shiftsPlanningResponse.exists) {
              let tomorrowsShiftKeys = [];
              let shiftsPlanning = shiftsPlanningResponse.data();
              Object.keys(shiftsPlanning).forEach((shiftKey) => {
                if (
                  shiftKey.includes(this.isoDate) &&
                  isEqual(shiftsPlanning[shiftKey], employeeId)
                ) {
                  tomorrowsShiftKeys.push(shiftKey);
                }
              });
              //END Get shiftPlanning
              //START Get clients data
              let clientsIds = [];
              let clientsPromises = [];
              tomorrowsShiftKeys.forEach((shiftKey) => {
                let [clientId, year, month, date, shiftNr] =
                  shiftKey.split("-");
                if (
                  isNil(this.clientsList[clientId]) &&
                  !clientsIds.includes(clientId)
                ) {
                  clientsIds.push(clientId);
                  clientsPromises.push(
                    db.collection("clients").doc(clientId).get()
                  );
                }
              });
              let clientsResponse = await Promise.all(clientsPromises);
              let clientsData = {};
              clientsResponse.forEach((response) => {
                let data = response.data();
                data.clientId = response.id;
                clientsData[response.id] = data;
              });
              this.clientsList = Object.assign(this.clientsList, clientsData);
              //END Get clients data
              let keysSorted = tomorrowsShiftKeys.sort(function (a, b) {
                let [aClientId, aYear, aMonth, aDate, aShiftNr] = a.split("-");
                let [bClientId, bYear, bMonth, bDate, bShiftNr] = b.split("-");
                let aClient = clientsData[aClientId];
                let bClient = clientsData[bClientId];
                if (
                  isEqual(aShiftNr, bShiftNr) &&
                  !isNil(aClient.visitsScheduleTimes) &&
                  !isNil(bClient.visitsScheduleTimes)
                ) {
                  let aScheduleTime = aClient.visitsScheduleTimes.find((time) =>
                    isEqual(time.index, parseInt(aShiftNr))
                  );
                  let bScheduleTime = bClient.visitsScheduleTimes.find((time) =>
                    isEqual(time.index, parseInt(bShiftNr))
                  );
                  if (aScheduleTime && bScheduleTime) {
                    let aScheduleTimeInMinutes =
                      parseInt(aScheduleTime.hours) * 60 +
                      parseInt(aScheduleTime.minutes);
                    let bScheduleTimeInMinutes =
                      parseInt(bScheduleTime.hours) * 60 +
                      parseInt(bScheduleTime.minutes);
                    return aScheduleTimeInMinutes - bScheduleTimeInMinutes;
                  }
                } else {
                  return parseInt(aShiftNr) - parseInt(bShiftNr);
                }
              });
              let keysFiltered = [];
              keysSorted.forEach((key) => {
                let [clientId, year, month, date, shiftNr] = key.split("-");
                let client = clientsData[clientId];
                if (
                  client &&
                  client.clientStatus &&
                  !isEqual(client.clientStatus, "stopped")
                ) {
                  keysFiltered.push(key);
                }
              });
              //START Get shifts times
              // let shiftsTimesResponse = await db
              //   .collection("shiftPlanningScheduler")
              //   .doc(`${documentKeyFirstPart}-shiftTimes`)
              //   .get();
              // let shiftsTimes = shiftsTimesResponse.data();
              // let tomorrowsShiftTimes = {};
              // tomorrowsShiftKeys.forEach((shiftKey) => {
              //   tomorrowsShiftTimes[shiftKey] = shiftsTimes[shiftKey];
              // });
              // let keysSorted = Object.keys(tomorrowsShiftTimes).sort(
              //   (a, b) => tomorrowsShiftTimes[a] - tomorrowsShiftTimes[b]
              // );
              // this.sheduledVisitsTimes = Object.assign({}, tomorrowsShiftTimes);
              this.sortedShiftKeys = keysFiltered;
              //END Get shifts times
            }
          }
        }
      }
    },
  },
};
</script>
