<template>
  <v-row justify="center" v-if="showDialog">
    <v-dialog persistent v-model="showDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="11">
              <h4>
                <span
                  :class="
                    'task-group-' +
                    taskDataInDialog.lpTaskNumber.toString().slice(0, 1) +
                    ' span-number'
                  "
                  >{{ taskDataInDialog.lpTaskNumber }}</span
                >
                {{ taskDataInDialog.lpTaskName }}
                {{ isRepositioningTask ? ` (${taskDataInDialog.order})` : "" }}
                -

                {{ taskDataInDialog.clientName }}

                <!-- postponed -->
                <v-tooltip
                  right
                  v-if="typeof taskDataInDialog.postponed != 'undefined'"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      (
                      <v-icon color="accent" small>fast_forward</v-icon>)
                    </span>
                  </template>
                  <span>{{ $t("tasks.moved-task") }}</span>
                </v-tooltip>

                <!-- task icon -->
                <v-tooltip right v-if="taskDataInDialog.lpTaskSocial">
                  <template v-slot:activator="{ on }">
                    <v-icon color="accent" small v-on="on">cake</v-icon>
                  </template>
                  <span>{{ $t("tasks.social-task") }}</span>
                </v-tooltip>
                <v-tooltip right v-if="taskDataInDialog.lpTaskCognitive">
                  <template v-slot:activator="{ on }">
                    <v-icon color="accent" small v-on="on">extension</v-icon>
                  </template>
                  <span>{{ $t("tasks.cognitive-task") }}</span>
                </v-tooltip>
                <v-tooltip right v-if="taskDataInDialog.lpTaskEveryday">
                  <template v-slot:activator="{ on }">
                    <v-icon color="accent" small v-on="on">repeat</v-icon>
                  </template>
                  <span>{{ $t("tasks.daily-task") }}</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="1">
              <v-btn
                color="error"
                fab
                @click="closeTaskDialog()"
                outlined
                small
              >
                <v-icon>close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <!-- buttons -->
          <v-layout wrap justify-center v-if="!isRepositioningTask">
            <template>
              <!-- OLD BUTTONS -->
              <span v-if="taskDataInDialog.lpTaskDirect == 'Tiešs'">
                <v-btn
                  id="assisted-btn"
                  class="narrow-btn"
                  fab
                  small
                  depressed
                  outlined
                  @click="updateStatus('assisted')"
                  v-bind:class="updateBtnClass('assisted')"
                >
                  <v-icon v-if="!taskDataInDialog.lpTaskCognitive"
                    >people</v-icon
                  >
                  <v-icon v-else>sentiment_very_dissatisfied</v-icon>
                </v-btn>
                <v-btn
                  id="supervised-btn"
                  class="narrow-btn"
                  fab
                  small
                  depressed
                  outlined
                  @click="updateStatus('supervised')"
                  v-bind:class="updateBtnClass('supervised')"
                >
                  <v-icon v-if="!taskDataInDialog.lpTaskCognitive"
                    >visibility</v-icon
                  >
                  <v-icon v-else>sentiment_dissatisfied</v-icon>
                </v-btn>
                <v-btn
                  id="independent-btn"
                  class="narrow-btn"
                  fab
                  small
                  depressed
                  outlined
                  @click="updateStatus('independent')"
                  v-bind:class="updateBtnClass('independent')"
                >
                  <v-icon v-if="!taskDataInDialog.lpTaskCognitive"
                    >person</v-icon
                  >
                  <v-icon v-else>sentiment_very_satisfied</v-icon>
                </v-btn>
              </span>

              <span
                v-if="
                  taskDataInDialog.lpTaskDirect == 'Netiešs' &&
                  !taskDataInDialog.lpFunctionTask
                "
              >
                <v-btn
                  id="doneIdr-btn"
                  class="narrow-btn"
                  fab
                  small
                  depressed
                  outlined
                  @click="updateStatus('doneIdr')"
                  v-bind:class="updateBtnClass('doneIdr')"
                >
                  <v-icon>check_circle_outline</v-icon>
                </v-btn>
              </span>
            </template>
            <!-- cancel or forward only if has comment -->

            <span
              v-if="taskDataInDialog.comment != '' && taskDataInDialog.comment"
            >
              <v-btn
                id="canceled-btn"
                class="narrow-btn"
                fab
                small
                depressed
                outlined
                @click="updateStatus('canceled')"
                v-bind:class="updateBtnClass('canceled')"
              >
                <v-icon>cancel</v-icon>
              </v-btn>
              <v-btn
                v-if="!taskDataInDialog.lpTaskEveryday"
                id="postponed-btn"
                class="narrow-btn"
                fab
                small
                depressed
                outlined
                @click="updateStatus('postponed')"
                v-bind:class="updateBtnClass('postponed')"
              >
                <v-icon>fast_forward</v-icon>
              </v-btn>
            </span>

            <v-btn
              id="open-btn"
              class="narrow-btn"
              fab
              small
              depressed
              outlined
              @click="updateStatus('open')"
              v-bind:class="updateBtnClass('open')"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
          </v-layout>
          <v-list dense>
            <!-- general info -->
            <v-list-item>
              <v-list-item-content>
                <span>
                  <strong>{{ $t("form.date") }}:</strong>
                  {{ taskDataInDialog.lpTaskFormatDate }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <span>
                  <strong>{{ $t("users.responsible") }}:</strong>
                  {{ source.userData.displayName }}
                </span>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <span>
                  <strong>{{ $t("tasks.status") }}:</strong>
                  {{ statusToText(taskDataInDialog.status) }}
                </span>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <!-- task descriptions -->
          <template
            v-if="
              taskDataInDialog.lpTaskDirect == 'Tiešs' &&
              source.taskDescription &&
              source.taskDescription.taskDescription
            "
          >
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >{{
                    $t(
                      isRepositioningTask
                        ? "tasks.repositioning-position"
                        : "tasks.task-description"
                    )
                  }}:</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <div v-if="!isRepositioningTask">
              <v-list-item>
                <v-list-item-content>
                  <p>
                    <v-btn
                      id="assisted-btn"
                      class="narrow-btn"
                      fab
                      small
                      depressed
                      outlined
                      @click="updateStatus('assisted')"
                      v-bind:class="updateBtnClass('assisted')"
                    >
                      <v-icon v-if="!taskDataInDialog.lpTaskCognitive">
                        people
                      </v-icon>
                      <v-icon v-else>sentiment_very_dissatisfied</v-icon>
                    </v-btn>
                    <strong>{{ $t("profiling.levelOfAssistance.C") }}:</strong>
                    {{ source.taskDescription.taskDescription.assisted }}
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <p>
                    <v-btn
                      id="supervised-btn"
                      class="narrow-btn"
                      fab
                      small
                      depressed
                      outlined
                      @click="updateStatus('supervised')"
                      v-bind:class="updateBtnClass('supervised')"
                    >
                      <v-icon v-if="!taskDataInDialog.lpTaskCognitive"
                        >visibility</v-icon
                      >
                      <v-icon v-else>sentiment_dissatisfied</v-icon>
                    </v-btn>
                    <strong>{{ $t("profiling.levelOfAssistance.B") }}:</strong>
                    {{ source.taskDescription.taskDescription.supervised }}
                  </p>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <p>
                    <v-btn
                      id="independent-btn"
                      class="narrow-btn"
                      fab
                      small
                      depressed
                      outlined
                      @click="updateStatus('independent')"
                      v-bind:class="updateBtnClass('independent')"
                    >
                      <v-icon v-if="!taskDataInDialog.lpTaskCognitive"
                        >person</v-icon
                      >
                      <v-icon v-else>sentiment_very_satisfied</v-icon>
                    </v-btn>
                    <strong>{{ $t("tasks.independently") }}:</strong>
                    {{ source.taskDescription.taskDescription.independent }}
                  </p>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div v-else class="ml-3 mb-5">
              <custom-button
                :text="$t('client.repositioning-positions.on-left-side')"
                dynamicClass="mb-3"
                @click="updateStatus('assisted', null, 'on-left-side')"
              />
              <custom-button
                :text="$t('client.repositioning-positions.on-right-side')"
                dynamicClass="mb-3"
                @click="updateStatus('assisted', null, 'on-right-side')"
              />
              <custom-button
                :text="$t('client.repositioning-positions.on-back')"
                dynamicClass="mb-3"
                @click="updateStatus('assisted', null, 'on-back')"
              />
              <custom-button
                :text="$t('client.repositioning-positions.seated-in-bed')"
                dynamicClass="mb-3"
                @click="updateStatus('assisted', null, 'seated-in-bed')"
              />
              <custom-button
                :text="
                  $t('client.repositioning-positions.seated-in-wheelchair')
                "
                dynamicClass="mb-3"
                @click="updateStatus('assisted', null, 'seated-in-wheelchair')"
              />
            </div>

            <v-divider></v-divider>
          </template>

          <!-- comment -->
          <!-- {{ dialogTaskId + "comment" }} -->
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("tasks.comments") }}:</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field
                  text
                  outlined
                  :value="taskDataInDialog.comment"
                  :label="$t('tasks.add-comment')"
                  :id="'comment-box'"
                  @blur="saveComment()"
                ></v-text-field>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon v-if="!saving">check</v-icon>
              <v-progress-circular
                indeterminate
                color="secondary"
                :size="20"
                width="2"
                v-else
              ></v-progress-circular>
            </v-list-item-action>
          </v-list-item>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ $t("global.photo") }}</v-list-item-title>
              <v-list-item-subtitle>
                <my-task-quick-photo
                  :taskDataInDialog="taskDataInDialog"
                  :taskId="dialogTaskId"
                  @updatePhotoUrl="updatePhotoUrl"
                ></my-task-quick-photo>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- postponed notes -->
          <v-list-item v-if="typeof taskDataInDialog.postponed != 'undefined'">
            <v-list-item-content>
              <v-list-item-title
                >{{ $t("tasks.task-is-postponed") }}:
              </v-list-item-title>
              <v-list-item-subtitle
                v-for="(postpDetails, index) in taskDataInDialog.postponed"
                :key="index"
              >
                {{ postpDetails.date }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- last update -->
          <v-list-item v-if="typeof taskDataInDialog.updatedBy != 'undefined'">
            <v-list-item-content>
              <p>
                <small style="font-style: italic">
                  {{ $t("global.last-updates") }}:
                  {{ taskDataInDialog.updatedBy }}
                  {{
                    formatDateTime(
                      taskDataInDialog.lastUpdate._seconds ||
                        taskDataInDialog.lastUpdate.seconds ||
                        taskDataInDialog.lastUpdate.getTime() / 1000
                    )
                  }}
                </small>
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="closeTaskDialog()" outlined>
            <v-icon left>close</v-icon>{{ $t("btn.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { repositioningTaskId, statusToTxt } from "@/helpers/constants";
import { formatDateTime } from "@/helpers/helperFns.js";

import { db, auth } from "@/main";
//debounce https://fireship.io/lessons/vue-autosaving-forms-with-firestore/
import { debounce } from "debounce";
import { isEqual, isNil } from "lodash";

import MyTaskQuickPhoto from "@/views/mytasks/AddPhoto.vue";
import CustomButton from "@/components/CustomButton.vue";

export default {
  name: "one-task-dialog",
  props: ["dialogTaskId", "dialogTaskData", "showDialog", "source", "index"],
  components: { MyTaskQuickPhoto, CustomButton },
  data() {
    return {
      taskDataInDialog: {},
      saving: false,
      isRepositioningTask: false,
    };
  },
  watch: {
    showDialog: function (val) {
      this.taskDataInDialog = Object.assign({}, this.dialogTaskData);
      if (!isNil(this.dialogTaskData.lpTaskId)) {
        this.isRepositioningTask = isEqual(
          this.dialogTaskData.lpTaskId,
          repositioningTaskId
        );
      }
    },
  },
  methods: {
    autoCloseTask() {
      let vm = this;
      //close task if added photo and it is indirect task
      if (
        vm.taskDataInDialog.lpTaskDirect == "Netiešs" &&
        !vm.taskDataInDialog.lpFunctionTask &&
        vm.taskDataInDialog.photoUrl.length > 0
      ) {
        setTimeout(function () {
          vm.updateStatus("doneIdr", "auto added photo");
        }, 2000);
      }
    },
    updatePhotoUrl(newUrl) {
      this.taskDataInDialog.photoUrl = newUrl;
      //update locally
      this.$emit("updTaskFromDialog", this.dialogTaskId, "photoUrl", newUrl);
    },
    async updateStatus(status, closeMethod, repositioningStatus) {
      let vm = this;
      //close dialog
      vm.closeTaskDialog();

      //save in background

      const closedAt = new Date();
      //update in dialog
      vm.taskDataInDialog["status"] = status;
      vm.taskDataInDialog["closedAt"] = closedAt;
      vm.taskDataInDialog["closedBy"] = auth.currentUser.uid;
      vm.taskDataInDialog["closedType"] = closeMethod || "single manual";
      vm.taskDataInDialog["lastUpdate"] = closedAt;
      //This is needed to prevent bug in firebase
      vm.taskDataInDialog["lpTaskDate"] = new Date();

      if (!isNil(repositioningStatus)) {
        vm.taskDataInDialog["repositioningStatus"] = repositioningStatus;
      }
      vm.taskDataInDialog["updatedBy"] = `${
        this.$root.currentUserData.displayName
      } ${vm.$t("tasks.updated-task-status")} (single)`;
      //{{ $t("tasks.add-comment") }}
      let updObj = vm.taskDataInDialog;
      updObj.closedAt = closedAt;

      ["lastUpdate", "closedType", "closedBy", "closedAt", "status"].forEach(
        (field) => {
          this.$emit(
            "updTaskFromDialog",
            this.dialogTaskId,
            field,
            updObj[`${field}`]
          );
        }
      );

      //don't await to use offline capabilities
      db.collection("taskArchive").doc(this.dialogTaskId).update(updObj);
    },
    async saveComment() {
      var vm = this;
      //feedback
      vm.saving = true;
      const textFieldElement = document.getElementById("comment-box");
      if (!isNil(textFieldElement)) {
        const newComment = textFieldElement.value;
        vm.taskDataInDialog.comment = newComment;
        //update locally
        vm.$emit("updTaskFromDialog", vm.dialogTaskId, "comment", newComment);
        //database
        await db
          .collection("taskArchive")
          .doc(this.dialogTaskId)
          .update({ comment: newComment });
      }

      vm.saving = false;
    },
    closeTaskDialog() {
      this.$emit("closeTaskDialog");
    },
    statusToText(status) {
      const statusList = statusToTxt();
      return statusList[status];
    },

    updateBtnClass(btnStatus) {
      if (btnStatus == this.taskDataInDialog.status) {
        return "active-btn";
      }
    },
    formatDateTime(seconds) {
      return formatDateTime(seconds);
    },
  },
};
</script>

<style scoped>
.span-number {
  padding-left: 10px;
  padding-right: 5px;
}
.active-btn {
  background-color: lightgrey;
}
.narrow-btn {
  margin: 0;
  margin-right: 5px;
}
.btn-letter {
  font-size: 2em;
}
.repositioningBtn {
  max-width: 100px;
  width: 100px;
  margin-bottom: 15px;
}
</style>