<template>
  <div>
    <!-- 3 types of items -->
    <!-- shifts -->
    <v-toolbar color="primary" dark v-if="source.type == 'shift-title'" v-once>
      <v-toolbar-title>{{ source.data }}</v-toolbar-title>
    </v-toolbar>
    <!-- clients -->
    <v-toolbar
      class="client-title"
      dark
      v-if="source.type == 'client-title'"
      v-once
    >
      <v-toolbar-title>{{ source.data }}</v-toolbar-title>
    </v-toolbar>

    <!-- task tiles -->
    <v-col cols="12" v-if="source.type == 'no-tasks'" v-once>
      <h4>{{ $t("tasks.no-tasks-selected") }}</h4>
    </v-col>
    <v-col cols="12" v-if="source.type == 'all-tasks-completed'" v-once>
      <h4>{{ $t("tasks.all-tasks-completed") }}</h4>
    </v-col>
    <v-list-item v-if="source.type == 'task'" @click.stop="openDialog()">
      <v-list-item-content
        v-bind:class="taskData.status != 'open' ? 'checkout' : ''"
      >
        <v-list-item-subtitle>
          <!-- closed task icons -->
          <span v-if="taskData.status == 'assisted'">
            <v-icon small>people</v-icon>
          </span>
          <span v-if="taskData.status == 'supervised'">
            <v-icon small>visibility</v-icon>
          </span>
          <span v-if="taskData.status == 'independent'">
            <v-icon small>person</v-icon>
          </span>
          <span v-if="taskData.status == 'canceled'">
            <v-icon small>cancel</v-icon>
          </span>
          <span v-if="taskData.status == 'postponed'">
            <v-icon small>fast_forward</v-icon>
          </span>
          <span v-if="taskData.status == 'doneIdr'">
            <v-icon small>check_circle_outline</v-icon>
          </span>

          <!-- task number -->
          <span
            :class="
              'task-group-' + taskData.lpTaskNumber.toString().slice(0, 1)
            "
            >{{ taskData.lpTaskNumber }}</span
          >
          <!-- task name -->
          {{ taskData.lpTaskName }}
          {{ isRepositioningTask ? ` (${taskData.order})` : "" }}
          <!-- postponed -->
          <v-tooltip right v-if="typeof taskData.postponed != 'undefined'">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                (
                <v-icon color="accent" small>fast_forward</v-icon>)
              </span>
            </template>
            <span>{{ $t("tasks.moved-task") }}</span>
          </v-tooltip>

          <!-- task icon -->
          <v-tooltip right v-if="taskData.lpTaskSocial">
            <template v-slot:activator="{ on }">
              <v-icon color="accent" small v-on="on">cake</v-icon>
            </template>
            <span>{{ $t("tasks.social-task") }}</span>
          </v-tooltip>
          <v-tooltip right v-if="taskData.lpTaskCognitive">
            <template v-slot:activator="{ on }">
              <v-icon color="accent" small v-on="on">extension</v-icon>
            </template>
            <span>{{ $t("tasks.cognitive-task") }}</span>
          </v-tooltip>
          <v-tooltip right v-if="taskData.lpTaskEveryday">
            <template v-slot:activator="{ on }">
              <v-icon color="accent" small v-on="on">repeat</v-icon>
            </template>
            <span>{{ $t("tasks.daily-task") }}</span>
          </v-tooltip>
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
//item state mixin
import keepStateMixin from "@/mixins/itemState";
import { repositioningTaskId } from "@/helpers/constants";
import { isEqual, isNil } from "lodash";

export default {
  name: "task-tile",
  mixins: [keepStateMixin],
  props: {
    index: {
      type: Number,
    },
    source: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    taskData: {},
    isRepositioningTask: false,
  }),
  watch: {
    source: function (newVal, oldVal) {
      this.taskData = newVal.data;
    },
  },
  created() {
    if (this.source.type == "task") {
      this.taskData = Object.assign({}, this.source.data);
      if (!isNil(this.taskData.lpTaskId)) {
        this.isRepositioningTask = isEqual(
          this.taskData.lpTaskId,
          repositioningTaskId
        );
      }
    }
  },
  methods: {
    //send: open / close task dialog
    openDialog() {
      this.dispatch(
        "my-tasks",
        "openDialog",
        this.source.id,
        this.source,
        this.index
      );
    },
  },
};
</script>

<style scoped>
.client-title {
  background-color: #afcce0 !important;
}
.checkout {
  text-decoration: line-through;
  color: rgb(155, 154, 154);
}
</style>
